import { getRequest,postRequest } from "@/http/request_service.js";
import { API } from "@/api/api.js"

export const memberList=(params)=>{
    return getRequest(API.memberList,params);
}

// 區域列表
export const getRegionList=(params)=>{
    return getRequest(API.regionList,params);
}
// 會員統計
export const getMemberStatistics=()=>{
    return postRequest(API.memberStatistics);
}
// 編輯
export const memberEdit=(params)=>{
    return postRequest(API.memberEdit,params);
}
// 獲取点数列表
export const getMemberBonusList=(params)=>{
    return getRequest(API.memberBonusList+'/'+params);
}
// 導出
export const memberBonusExportReports=(params)=>{
    return postRequest(API.memberBonusExportReport+'/'+params);
}
// 導出
export const memberExportReports=(params)=>{
    return postRequest(API.memberBExportReport,params);
}
// 導出
export const memberBirthdayUpdateRecord=(params)=>{
    return getRequest(API.memberBirthdayUpdateRecord + '/' + params);
}
//会员等级
export const memberLevelTypeList=(params)=>{
    return getRequest(API.memberLevelTypeList,params);
}

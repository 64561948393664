<template>
  <div class="mainwrap userlist" v-loading="loading">
    <div class="title">
      會員管理
      <div class="boxs" v-if="memberCon">
        <div>會員總數: {{memberCon.totleMember || 0}}</div>
        <div>新會員總數: {{memberCon.newMember || 0}}</div>
        <div>沉睡會員總數: {{memberCon.sleepMember || 0}}</div>
      </div>
      <div class="btn"><el-button type="primary" @click="getMemberStatistics()">更 新</el-button></div>
    </div>

    <div class="searchform">
      <el-form
        label-width="100px"
        :inline="true"
        ref="searchForm"
        :model="searchForm"
        class="demo-form-inline"
      >
        <el-form-item label="姓名：">
          <el-input class="w_50" v-model="searchForm.lastname" placeholder="請輸入姓"></el-input>
          <el-input class="w_50" v-model="searchForm.username" placeholder="請輸入名"></el-input>
        </el-form-item>
        <el-form-item label="電話：">
          <el-input v-model="searchForm.phone" placeholder="請輸入電話"></el-input>
        </el-form-item>
        <el-form-item label="是否會員：">
          <el-select v-model="searchForm.isMember" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="會員等級：">
          <el-select
              v-model="searchForm.Level"
              placeholder="請選擇會員等級"
          >
            <el-option
                v-for="item in levelList"
                :key="item.key"
                :label="item.value"
                :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="居住地區：">
            <el-select v-model="searchForm.City" placeholder="请选择" @change="changeRegion2">
                <el-option label="請選擇" value=""></el-option>
                <el-option v-for="item in parentRegionList" :key="item.key" :label="item.value" :value="item.key"></el-option>
            </el-select>
            <el-select v-model="searchForm.Region" placeholder="请选择">
                <el-option label="請選擇" value=""></el-option>
                <el-option v-for="item in subRegionList" :key="item.key" :label="item.value" :value="item.key"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="生日：" prop="Birthday">
          <el-date-picker
              v-model="searchForm.BirthdayStart"
              type="date"
              value-format="yyyy-MM-dd"
              :default-value="defaultDateValue1"
              :picker-options="pickerOptions1"
              placeholder="選擇日期">
          </el-date-picker>
          -
          <el-date-picker
              v-model="searchForm.BirthdayEnd"
              type="date"
              value-format="yyyy-MM-dd"
              :default-value="defaultDateValue2"
              :picker-options="pickerOptions2"
              placeholder="選擇日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="消費次數：" prop="PaymentNum">
          <el-input style="width: 220px;" type="number" v-model="searchForm.PaymentNumStart" placeholder="請輸入"></el-input>
          -
          <el-input style="width: 220px;" type="number" v-model="searchForm.PaymentNumEnd" placeholder="請輸入"></el-input>
        </el-form-item>
        <el-form-item label="消費金額：" prop="PaymentAmount">
          <el-input style="width: 220px;" type="number" v-model="searchForm.PaymentAmountStart" placeholder="請輸入"></el-input>
          -
          <el-input style="width: 220px;" type="number" v-model="searchForm.PaymentAmountEnd" placeholder="請輸入"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch()">查询</el-button>
          <el-button type="success" @click="listDownLoad" :disabled="disabledDown2">下載</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div style="text-align: right;color: #666;font-size: 14px;">會員查詢總數: {{tableDataTotal}}</div>
    </div>
    <div class="tablewrap">
      <el-table :data="tableData" style="width: 100%" stripe>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button v-if="permissionType === 2" @click="handleEdit(scope.row)" type="primary" plain>
              编辑
            </el-button>
            <el-button @click="isOpen(scope.row)" type="primary" plain>
              點數歷程
            </el-button>
          </template>
        </el-table-column>
<!--        <el-table-column prop="uuId" label="客戶編號"> </el-table-column>-->
        <el-table-column prop="creationTime" label="註冊的日期時間"> </el-table-column>
        <el-table-column label="中文姓名/英文姓名">
          <template slot-scope="scope">
            <span>
              {{scope.row.lastName!=null?scope.row.lastName:""}}{{scope.row.name!=null?scope.row.name:""}}
            </span>
          </template>
        </el-table-column>
<!--        <el-table-column prop="" label="頭像"> </el-table-column>-->
        <el-table-column prop="isHsvibe" label="是否漢美會員"> </el-table-column>
        <el-table-column prop="custp" label="類別"> </el-table-column>
        <el-table-column prop="level" label="會員等級"> </el-table-column>
        <el-table-column prop="sex" label="性別"> </el-table-column>
        <el-table-column label="生日">
          <template slot-scope="scope">
            <span style="cursor: pointer;color: #409EFF;" @click="lookModifyNum(scope.row)">{{scope.row.birthday}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="setBirthdayTime" width="150" label="填寫生日的時間"> </el-table-column>
        <!-- <el-table-column prop="cardID" label="身份證字號"> </el-table-column>
        <el-table-column prop="merry" label="婚姻"> </el-table-column>
        <el-table-column prop="class" label="等級碼"> </el-table-column>
        <el-table-column prop="source" label="來源碼"> </el-table-column>
        <el-table-column prop="comptp" label="公司行業"> </el-table-column>
        <el-table-column prop="orgtp" label="社團"> </el-table-column>
        <el-table-column prop="contel" label="聯絡電話"> </el-table-column> -->
        <el-table-column prop="mobile" label="行動電話"> </el-table-column>
        <!-- <el-table-column prop="faxno" label="傳真號碼"> </el-table-column> -->
        <el-table-column prop="email" label="電子郵件"> </el-table-column>
        <!-- <el-table-column prop="remdt" label="紀念日"> </el-table-column>
        <el-table-column prop="remtp" label="紀念別"> </el-table-column>
        <el-table-column prop="contnm" label="主要聯絡人姓名"> </el-table-column>
        <el-table-column prop="taxid" label="統一編號"> </el-table-column>
        <el-table-column prop="compnM1" label="公司名稱(中)"> </el-table-column>
        <el-table-column prop="compnM2" label="公司名稱(英)"> </el-table-column>
        <el-table-column prop="ziP3" label="公司地址郵遞號"> </el-table-column>
        <el-table-column prop="addR3" label="公司地址"> </el-table-column>
        <el-table-column prop="comemail" label="公司電子郵件"> </el-table-column>
        <el-table-column prop="comfax" label="公司傳真"> </el-table-column>
        <el-table-column prop="comtel" label="公司電話"> </el-table-column> -->
        <el-table-column prop="ziP1" label="通訊地址郵遞號"> </el-table-column>
        <el-table-column label="通訊地址">
          <template slot-scope="scope">
            <span>
              {{scope.row.regionStr}}{{scope.row.ziP1Str}}{{scope.row.addR1}}
            </span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="ziP2" label="住宅地址郵遞號"> </el-table-column> -->
<!--        <el-table-column prop="" label="居住縣市"> </el-table-column>-->
<!--        <el-table-column prop="" label="居住區域"> </el-table-column>-->
        <!-- <el-table-column prop="addR2" label="住宅地址"> </el-table-column>
        <el-table-column prop="mbno" label="會員編號_菁英卡"> </el-table-column>
        <el-table-column prop="mbnO1" label="會員編號_俱樂部"> </el-table-column> -->
        <el-table-column prop="latelyConsumptionDate" label="最近消費日期"> </el-table-column>
        <el-table-column prop="visitedTimes" label="總計來訪次數"> </el-table-column>
        <el-table-column prop="consumptionAmount" label="消費總金額"> </el-table-column>
        <!-- <el-table-column prop="restamt" label="實際消費金額"> </el-table-column> -->
        <!-- <el-table-column prop="realamp" label="餐廳預估金額"> </el-table-column>
        <el-table-column prop="habit" label="客人習性(訂席)"> </el-table-column>
        <el-table-column prop="birtH1" label="配偶生日"> </el-table-column>
        <el-table-column prop="opiD1" label="建檔人員"> </el-table-column>
        <el-table-column prop="opdT1" label="建檔日期"> </el-table-column>
        <el-table-column prop="optM1" label="建檔時間"> </el-table-column>
        <el-table-column prop="opiD2" label="異動人員"> </el-table-column>
        <el-table-column prop="optM2" label="異動時間"> </el-table-column> -->
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination
        background
        :hide-on-single-page="isSinglePage"
        layout="prev, pager, next,jumper"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="searchForm.maxResultCount"
        :current-page="currentPage"
        :total="tableDataTotal"
      >
      </el-pagination>
    </div>

    <el-dialog title="編輯" :visible.sync="userVisible" width="40%">
      <el-form :model="editForm" :rules="rules" ref="editForm" label-width="110px" class="editfrom">
        <el-form-item label="姓名">
          <el-input v-model="editForm.username" disabled></el-input>
        </el-form-item>
        <el-form-item label="電子郵件" prop="email">
          <el-input v-model="editForm.email"></el-input>
        </el-form-item>
        <el-form-item label="生日" prop="birthday">
          <el-date-picker
              v-model="editForm.birthday"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="選擇日期"
              :disabled="editForm.isEditBirthday"
              >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="居住縣市 區域" prop="region">
          <el-select v-model="editForm.region" placeholder="请选择" @change="changeRegion">
              <el-option label="請選擇" value=""></el-option>
              <el-option v-for="item in parentRegionList" :key="item.key" :label="item.value" :value="item.key"></el-option>
          </el-select>
          <el-select v-model="editForm.ziP1" placeholder="请选择">
              <el-option label="請選擇" value=""></el-option>
              <el-option v-for="item in subRegionList" :key="item.key" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="地址" prop="addr">
          <el-input v-model="editForm.addr"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelEdit">取 消</el-button>
        <el-button type="primary" @click="saveUser">保 存</el-button>
      </span>
    </el-dialog>

    <el-dialog v-if="point" title="點數紀錄" :visible.sync="open" width="40%">
      <!-- <div class="nav">
        <div class="two">點數紀錄</div>
      </div> -->
      <div class="niubOx">
          <el-button :disabled="disabledDown" type="danger" class="nanniu" @click="downLoad()">匯 出</el-button>
      </div>
      <div class="content">
        <div class="left">
          <div class="top">點數</div>
          <div class="bom">{{point.point}}</div>
        </div>
        <div class="right">
          <div class="top">即將到期的點數</div>
          <div class="bom">{{point.expiringPoint || '0'}}</div>
          <div class="date">{{point.expireDate ? point.expireDate+'到期' : ''}}</div>
        </div>
      </div>
      <div class="btnBox">
        <div class="btn1" @click="totals(1)" :class="{'actives' : actives == 1}">獲得記錄</div>
        <div class="btn2" @click="totals(2)" :class="{'actives' : actives == 2}">使用記錄</div>
      </div>
      <div class="box">
        <div class="listBox" v-if="pointList">
          <div class="list" v-for="(item,index) in pointList" :key="index">
            <div class="left">
              <div class="word">{{item.description}}</div>
              <div class="date">{{item.operateTime}}</div>
            </div>
            <div class="right" :type="item.operate == 'income' ? 1 : 2"><span>{{item.operate == 'income' ? '+ '+item.point : '- '+item.point}}</span>點</div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelEdit2">取 消</el-button>
        <el-button type="primary" @click="cancelEdit2">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="會員生日修改記錄" :visible.sync="isShowRecord" width="50%">
      <div class="box">
        <div class="tablewrap">
          <el-table :data="recordData" style="width: 100%" stripe>
            <el-table-column prop="modificationTime" label="修改時間"> </el-table-column>
            <el-table-column prop="birthday" label="修改後的生日"> </el-table-column>
            <el-table-column prop="sourceType" label="來源"> </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelEdit2">取 消</el-button>
        <el-button type="primary" @click="cancelEdit2">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as api from "./api";
import { getStore} from '@/utils/storage';
import {memberBirthdayUpdateRecord} from "./api";

export default {
  name: 'userList',
  data(){
    var validateRegion=(rule, value, callback) => {
        if(!this.editForm.region || !this.editForm.ziP1){
          callback(new Error('请選擇居住縣市區域'));
        }else{
          callback();
        }
    }
    return{
      defaultDateValue1:"",
      defaultDateValue2:"",
      pickerOptions1:{
        disabledDate:this.dealDisabledDate1,
      },
      pickerOptions2:{
        disabledDate:this.dealDisabledDate2,
      },
      isShowRecord: false,
      recordData: [],
      memberCon: null,
      loading: false,
      isSinglePage: false,
      disabledDown: true,
      disabledDown2: true,
      currentPage: 1,
      active: 1,
      actives: 1,
      point: null,
      tableDataTotal: 0,
      tableData: [],
      pointList: [],
      searchForm:{
        BirthdayStart: "",
        BirthdayEnd: "",
        PaymentNumStart: "",
        PaymentNumEnd: "",
        PaymentAmountStart: "",
        PaymentAmountEnd: "",
        Level: "",
        username:"",
        lastname:"",
        phone:"",
        isMember: "",
        sorting: '',
        City:"",
        Region:"",
        maxResultCount: 10
      },
      options: [{value: true,label: '是'},
        {value: false,label: '否'}
      ],
      editForm:{
        id:"",
        username:"",
        email:"",
        birthday:"",
        ziP1:"",
        region:"",
        addr:""
      },
      rules:{
        email:[{required: false, message: "請輸入郵箱", trigger: "blur"},
          {type: "email", message: "郵箱格式錯誤", trigger: "blur"}
        ],
        birthday:[{required: false, message: "請選擇日期", trigger: "blur"}],
        region: [ {required: false, message: "请選擇居住縣市區域", trigger: ["blur","change"]},
          // { validator: validateRegion, trigger: 'change' }
        ],
        addr:[{required: false, message: "請輸入地址", trigger: "blur"}]
      },
      parentRegionList:[],
      subRegionList:[],
      levelList: [],
      userVisible:false,
      open: false,
      ids: 0,
      permissionType: -1
    }
  },
  methods:{
    // 計算禁用日期
    dealDisabledDate1(time){
      if(this.searchForm.BirthdayEnd){
        this.defaultDateValue1=this.searchForm.BirthdayEnd;
        return  time.getTime() > new Date(this.searchForm.BirthdayEnd);
      }else{
        this.defaultDateValue1="";
        return false
      }
    },
    // 計算禁用日期
    dealDisabledDate2(time){
      if(this.searchForm.BirthdayStart){
        this.defaultDateValue2=this.searchForm.BirthdayStart;
        return time.getTime() < new Date(this.searchForm.BirthdayStart) - 24 * 60 * 60 * 1000;
      }else{
        this.defaultDateValue2="";
        return false
      }
    },
    //獲取會員等級
    getMemberLevelTypeList() {
      this.loading = true;
      api.memberLevelTypeList().then((res) => {
        if (res.systemCode === 200) {
          this.levelList = res.data;
        }
        this.loading = false;
      });
    },
    // 獲取會員統計
    getMemberStatistics() {
      this.loading=true;
      api.getMemberStatistics().then(res=> {
        if(res.systemCode===200){
          this.memberCon = res.data
        }
        this.loading=false;
      })
    },
    // 下載
    downLoad() {
      this.loading=true;
      this.disabledDown=true;
      api.memberBonusExportReports(this.ids).then(res=>{
        if(res.systemCode===200){
            window.open(res.data,"_blank")
        }
        this.disabledDown=false;
        this.loading=false;
      })
    },
    listDownLoad() {
      this.loading=true;
      this.disabledDown2=true;
      let params ={
        name: this.searchForm.username,
        lastName: this.searchForm.lastname,
        mobile: this.searchForm.phone,
        isHsvibe: this.searchForm.isMember ? this.searchForm.isMember : null,
        sorting: this.searchForm.sorting,
        skipCount: (this.currentPage - 1) * this.searchForm.maxResultCount,
        maxResultCount: this.searchForm.maxResultCount,
        city:this.searchForm.City,
        region:this.searchForm.Region,
        birthdayStart: this.searchForm.BirthdayStart ? this.searchForm.BirthdayStart + " " + "00:00:00" : "",
        birthdayEnd: this.searchForm.BirthdayEnd ? this.searchForm.BirthdayEnd + " " + "23:59:59" : "",
        paymentNumStart: this.searchForm.PaymentNumStart === "" ? 1 : this.searchForm.PaymentNumStart,
        paymentNumEnd: this.searchForm.PaymentNumEnd,
        paymentAmountStart: this.searchForm.PaymentAmountStart,
        paymentAmountEnd: this.searchForm.PaymentAmountEnd,
        level: this.searchForm.Level,
      }
      if(!params.birthdayStart){
        delete params.birthdayStart
      }
      if(!params.birthdayEnd){
        delete params.birthdayEnd
      }
      if(!params.paymentNumStart){
        delete params.paymentNumStart
      }
      if(!params.paymentNumEnd){
        delete params.paymentNumEnd
      }
      if(!params.paymentAmountStart){
        delete params.paymentAmountStart
      }
      if(!params.paymentAmountEnd){
        delete params.paymentAmountEnd
      }
      // console.log(this.searchForm.Level,params.level,777)
      // // return
      if(params.level === ""){
        delete params.level
      }
      console.log(params.level)
      // return
      api.memberExportReports(params).then(res=>{
        if(res.systemCode===200){
            window.open(res.data,"_blank")
        }
        this.disabledDown2=false;
        this.loading=false;
      })
    },
    // 查詢
    onSearch() {
      this.currentPage=1;
      this.getMemberList()
    },
    resetForm() {
      this.searchForm = {
        BirthdayStart: "",
        BirthdayEnd: "",
        PaymentNumStart: "",
        PaymentNumEnd: "",
        PaymentAmountStart: "",
        PaymentAmountEnd: "",
        Level: "",
        username:"",
        lastname:"",
        phone:"",
        isMember: "",
        sorting: '',
        City:"",
        Region:"",
        maxResultCount: 10
      }
      this.currentPage=1;
      this.getMemberList();
    },
    // 翻頁
    handleCurrentChange(val) {
      this.currentPage=val;
      this.getMemberList()
    },
    getMemberList() {
      // 下面這段代碼好像沒用，先注釋
      /**
      let member = false
      if(this.searchForm.isMember == '是'){
        member = true
      }else if(this.searchForm.isMember == '否'){
        member = false
      }else{
        member =this.searchForm.isMember
      }
      */
      this.loading = true;
      api.memberList({
        Name: this.searchForm.username,
        LastName: this.searchForm.lastname,
        Mobile: this.searchForm.phone,
        IsHsvibe: this.searchForm.isMember,
        Sorting: this.searchForm.sorting,
        SkipCount: (this.currentPage - 1) * this.searchForm.maxResultCount,
        MaxResultCount: this.searchForm.maxResultCount,
        City:this.searchForm.City,
        Region:this.searchForm.Region,
        BirthdayEnd:this.searchForm.BirthdayEnd ? this.searchForm.BirthdayEnd + " " + "23:59:59" : "",
        BirthdayStart: this.searchForm.BirthdayStart ? this.searchForm.BirthdayStart + " " + "00:00:00" : "",
        PaymentAmountEnd: this.searchForm.PaymentAmountEnd,
        PaymentAmountStart: this.searchForm.PaymentAmountStart,
        PaymentNumEnd: this.searchForm.PaymentNumEnd,
        PaymentNumStart: this.searchForm.PaymentNumStart === "" ? 1 : this.searchForm.PaymentNumStart,
        Level: this.searchForm.Level,
      }).then(res=>{
        if(res.systemCode===200){
          this.tableData = res.data.items
          this.tableDataTotal = res.data.totalCount
        }
        this.loading = false;
        this.disabledDown2=false;
      })
    },
    isOpen(item) {
      this.open = true
      console.log(item);
      this.ids = item.id
      this.getBonusList(item.id)
    },
    getBonusList(id) {
      this.loading = true;
      api.getMemberBonusList(id).then(res => {
        if(res.systemCode===200){
          this.disabledDown=false;
          this.point = res.data
          this.pointList = this.point.incomes
        }
        this.loading = false;
      })
    },
    lookModifyNum(item){
      api.memberBirthdayUpdateRecord(item.id).then(response=>{
        if(response.systemCode===200){
          this.recordData = response.data
          this.isShowRecord = true
        }
      })
    },
    totals(num) {
      this.actives = num
      if(num == 1) {
        this.pointList = this.point.incomes
      } else {
        this.pointList = this.point.expenses
      }
    },
    // 編輯
    async handleEdit(item){
      this.loading=true;
      let LN = item.lastName!=null?item.lastName:"",FN = item.name!=null?item.name:"";
      this.editForm.username =LN + FN
      this.editForm = Object.assign({},this.editForm,item)
      this.editForm.addr = item.addR1
      if(this.editForm.region){
        await this.getRegionData(this.editForm.region);
      }
      this.userVisible=true;
      this.$nextTick(()=>{
        this.$refs["editForm"].clearValidate();
      })
      this.loading=false;
    },
    // 保存
    saveUser(){
      this.$refs["editForm"].validate(valid => {
        if (valid) {
          this.loading=true;
          let params={
              id: this.editForm.id,
              email: this.editForm.email,
              birthirday: this.editForm.birthday,
              region: this.editForm.region,
              ziP1: this.editForm.ziP1,
              addR1: this.editForm.addr
          }
          api.memberEdit(params).then(res=>{
            if(res.systemCode===200){
              this.$message.success('提交成功');
              this.userVisible=false;
              this.getMemberList();
            }else{
              // this.$message.error(res.message);
            }
            this.loading=false;
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 取消
    cancelEdit(){
      // this.$refs["editForm"].clearValidate();
      this.userVisible=false;
    },
    cancelEdit2(){
      this.open=false;
      this.isShowRecord = false;
    },
    // 獲取區域
    getRegionData(parentId=""){
        let params={};
        if(parentId){
            params.parentId=parentId;
        }
        return api.getRegionList(params).then(response=>{
            if(response.systemCode===200){
                if(!parentId){
                    this.parentRegionList=response.data;
                }else{
                    this.subRegionList=response.data;
                }
            }
        })
    },
    changeRegion(val){
        this.editForm.ziP1="";
        if(val){
            this.getRegionData(val);
        }
    },
    changeRegion2(val){
        this.searchForm.Region="";
        if(val){
            this.getRegionData(val);
        }
    },
  },
  created() {
    this.permissionType = parseInt(getStore("permissionType"));
    this.getMemberLevelTypeList();
    this.getMemberList();
    this.getRegionData();
    this.getMemberStatistics();
  }
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
}
.boxs {
  margin-left: 50px;
  display: flex;
  font-size: 16px;
  div {
    margin-right: 30px;
  }
}
.userlist {
  .searchform {
    padding: 20px;
    border-bottom: 1px solid #ccc;
  }
  .w_50{
    width: 50%;
  }
  .editfrom{
    .el-input{
      width: 70%;
    }
  }
}
.niubOx {
  width: 100%;
  margin-bottom: 20px;
}
.nav {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  .one,.two {
    width: 50%;
    border-bottom: 1px solid #afafaf;
    padding-bottom: 20px;
    font-size: 20px;
    text-align: center;
    cursor: pointer;
  }
  .active {
    color: #409EFF;
    font-weight: 700;
    border-bottom: 2px solid #409EFF;
  }
}
.content {
  width: calc(100% - 40px);
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid #409EFF;
  padding: 20px;
  display: flex;
  .left,.right {
    width: 50%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .top {
      width: 100%;
      text-align: center;
      font-size: 16px;
      margin-bottom: 15px;
    }
    .bom {
      width: 100%;
      font-weight: 700;
      font-size: 20px;
      text-align: center;
      margin-bottom: 15px;
    }
  }
}
.btnBox {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  .btn1,.btn2 {
    padding: 10px 15px;
    font-size: 16px;
    margin-right: 15px;
    border-radius: 20px;
    background-color: #e9e9e9;
    cursor: pointer;
  }
  .actives {
    font-weight: 700;
    color: #409EFF;
    background-color: #cccccc;
  }
}
.box {
  height: 470px;
  overflow: auto;
  .listBox {
    width: 100%;
    .list {
      width: 100%;
      display: flex;
      margin-bottom: 20px;
      padding-bottom: 15px;
      border-bottom: 1px solid #e4e4e4;
      .left {
        width: 70%;
        display: flex;
        flex-wrap: wrap;
        .word {
          width: 100%;
          font-size: 16px;
          flex-wrap: 600;
          margin-bottom: 5px;
        }
        .date {
          font-size: 12px;
        }
      }
      .right {
        width: 30%;
        text-align: right;
        font-size: 14px;
        margin: auto 0;
        span {
          margin-right: 10px;
          font-size: 16px;
          color: #409EFF;
        }
      }
    }
  }
}
</style>
